<template>
  <q-form ref="editForm">
    <c-card title="일반작업 상세정보" class="cardClassDetailForm no-margin">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-text
            :required="true"
            :disabled="!isWriting"
            :editable="editable"
            label="작업개요"
            name="workSummary"
            v-model="workPermit.workSummary">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-process
            :disabled="!isWriting"
            :editable="editable"
            label="단위공정"
            name="processCd"
            v-model="workPermit.processCd">
          </c-process>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :editable="editable"
            :disabled="!isWriting"
            label="작업명"
            name="sopName"
            v-model="workPermit.sopName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <!-- 작업업체 -->
          <c-vendor
            :disabled="!isWriting"
            :editable="editable"
            label="LBL0000224"
            name="vendorCd"
            v-model="workPermit.vendorCd"
            @dataChange="vendorChange" />
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="!isWriting"
            :editable="editable"
            label="작업장소"
            name="workLocation"
            v-model="workPermit.workLocation">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            label="신청업체"
            name="request"
            v-model="request">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-2 col-lg-2">
          <c-field
            :required="true"
            :disabled="!isWriting"
            :editable="editable"
            :data="workPermit"
            :plantCd="workPermit.plantCd"
            deptValue="workManagerDeptCd"
            type="dept_user"
            userType="vendor"
            label="신청인 & 담당자"
            beforeText=""
            name="workManagerId"
            v-model="workPermit.workManagerId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-checkbox
            :required="true"
            :disabled="!isWriting"
            :editable="editable"
            codeGroupCd="SOP_PROTECTIVE_GEAR_CD"
            :isObject="true"
            valueText="sopProtectiveGearName"
            valueKey="sopProtectiveGearCd"
            label="보호구"
            name="protectiveGears"
            v-model="workPermit.protectiveGears"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-textarea
            :disabled="!isWriting"
            :editable="editable"
            :rows="2"
            label="특별요구사항"
            name="specialRequirements"
            v-model="workPermit.specialRequirements">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <c-table
          title="안전조치 요구사항"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="workPermit.checkResults"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          :contentsField="contentsField"
          @table-data-change="tableDataChange"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <c-card title="발급 정보" class="cardClassDetailForm no-margin">
              <template slot="card-detail">
                <div class="col-6">
                  <c-field
                    :required="true"
                    :disabled="!isWriting"
                    :editable="editable"
                    :data="workPermit"
                    :plantCd="workPermit.plantCd"
                    deptValue="issuedDeptCd"
                    userType="user"
                    type="dept_user"
                    label="발급"
                    beforeText=""
                    name="issuedUserId"
                    v-model="workPermit.issuedUserId" />
                </div>
                <div class="col-6">
                  <!-- 검토 -->
                  <c-field
                    :disabled="!isWriting"
                    :editable="editable"
                    :data="workPermit"
                    :deptCd="workPermit.issuedDeptCd"
                    :plantCd="workPermit.plantCd"
                    deptValue="examineDeptCd"
                    userType="user"
                    type="dept_user"
                    label="검토 (발급부서)"
                    beforeText=""
                    name="examineUserId"
                    v-model="workPermit.examineUserId" />
                </div>
                <div class="col-6">
                  <!-- 검토 -->
                  <c-field
                    :disabled="!isWriting"
                    :editable="editable"
                    :data="workPermit"
                    plantCd="DG"
                    deptCd="DG200"
                    deptValue="examineSafehealtyDeptCd"
                    userType="user"
                    type="dept_user"
                    label="검토 (안전보건팀)"
                    beforeText=""
                    name="examineSafehealtyUserId"
                    v-model="workPermit.examineSafehealtyUserId" />
                </div>
                <div class="col-6">
                  <!-- 승인 -->
                  <c-field
                    :disabled="!isWriting"
                    :editable="editable"
                    :data="workPermit"
                    :plantCd="workPermit.plantCd"
                    userType="user"
                    deptValue="approvalDeptCd"
                    type="dept_user"
                    label="승인"
                    beforeText=""
                    name="approvalUserId"
                    v-model="workPermit.approvalUserId" />
                </div>
              </template>
            </c-card>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <c-card title="관계 부서 협조자" class="cardClassDetailForm no-margin">
              <template slot="card-detail">
                <div class="col-12">
                  <c-field
                    :disabled="!isWriting"
                    :editable="editable"
                    :data="workPermit"
                    :plantCd="workPermit.plantCd"
                    deptValue="relationCooperation1DeptCd"
                    type="dept_user"
                    label="협조자-1"
                    beforeText=""
                    name="relationCooperation1UserId"
                    v-model="workPermit.relationCooperation1UserId" />
                </div>
                <div class="col-12">
                  <c-field
                    :disabled="!isWriting"
                    :editable="editable"
                    :data="workPermit"
                    :plantCd="workPermit.plantCd"
                    deptValue="relationCooperation2DeptCd"
                    type="dept_user"
                    label="협조자-2"
                    beforeText=""
                    name="relationCooperation2UserId"
                    v-model="workPermit.relationCooperation2UserId" />
                </div>
              </template>
            </c-card>
          </div>
            <!-- 가스농도 측정 -->
          <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <c-table
              ref="gasTable"
              title="LBL0000236"
              :columns="grid2.columns"
              :gridHeight="grid2.height"
              :data="workPermit.gases"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :editable="editable&&isWriting"
              selection="multiple"
              rowKey="sopGasCheckId"
            >
              <template slot="table-button">
                <q-btn-group outline >
                  <c-btn v-if="editable&&isWriting" label="추가" icon="add" :showLoading="false" @btnClicked="addGas" />
                  <c-btn v-if="editable&&isWriting" label="제외" icon="remove" :showLoading="false" @btnClicked="removeGas" />
                </q-btn-group>
              </template>
            </c-table>
          </div> -->
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import eventBus from '@/js/eventBus';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'swp-normal',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          examineDeptCd: '',  // 검토자 부서코드
          examineUserId: '',  // 검토자 ID
          examineSafehealtyDeptCd: '',  // 검토자 부서코드
          examineSafehealtyUserId: '',  // 검토자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          vendorFlag: 0, // 업체작성여부
          assessWriteUserId: '',  // 작업위험성평가_작성자 ID
          assessApprovalUserId: '',  // 작업위험성평가_승인자 ID
          sopConstructionId: '',
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          deleteCheckResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          assessments: [],
          deleteAssessments: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
        }
      }
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    saveCall: {
      type: Object,
      default: function() {
        return {
          saveCallData: '',
        }
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '580px'
      },
      grid2: {
        columns: [
          // 물질명
          {
            name: 'materialName',
            field: 'materialName',
            label: '물질명',
            align: 'left',
            sortable: false,
            type: 'text',
            style: 'width:30%',
          },
          // 결과
          {
            name: 'gasCheckMachine',
            field: 'gasCheckMachine',
            label: '결과',
            align: 'left',
            sortable: false,
            type: 'text',
            style: 'width:35%',
          },
          // 측정시간
          {
            name: 'checkTime',
            field: 'checkTime',
            label: 'LBL0000237',
            align: 'center',
            type: 'datetime',
            minuteStep: 10,
            sortable: false,
            style: 'width:15%',
          },
          // 측정자
          {
            name: 'gasCheckUserName',
            field: 'gasCheckUserName',
            label: '측정자',
            align: 'center',
            sortable: false,
            type: 'user',
            userId: 'gasCheckUserId',
            value: '',
            setHeader: true,
            style: 'width:15%',
          },
          // 확인자
          // {
          //   name: 'gasConfirmUserName',
          //   field: 'gasConfirmUserName',
          //   label: 'LBL0000240',
          //   align: 'center',
          //   sortable: false,
          //   type: 'user',
          //   userId: 'gasConfirmUserId',
          //   value: '',
          //   setHeader: true,
          //   style: 'width:10%',
          // },
        ],
        height: '382px'
      },
      useGasItems: [],
      sopEditing: false,
      editable: true,
      listCheckItemUrl: '',
      listGasItemUrl: '',
      listSopUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    request() {
      return this.workPermit.applicationUserName
      // return this.workPermit.applicationDeptName + ' / ' + this.workPermit.applicationUserName
    },
    isSopEdit() {
      return Boolean(!this.workPermit.mdmSopId && this.workPermit.sopName) || this.sopEditing
    },
  },
  watch: {
    'workPermit.plantCd'() {
      this.$set(this.workPermit, 'checkResults', [])
      this.$set(this.workPermit, 'gases', [])
      this.getCheckItemList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    eventBus.$on('vaildForm', (type)=>{
      if (this.$refs['editForm']) {
        this.$refs['editForm'].validate().then(_result => {
          this.$emit('validResult', _result, type);
        });
      }
    });
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listCheckItemUrl = selectConfig.sop.swp.workChekItem.list.url
      this.listGasItemUrl = selectConfig.sop.swp.gas.list.url
      this.listSopUrl = selectConfig.mdm.sop.jobhazard.url;
      // code setting
      // list setting
      this.getCheckItemList();
    },
    getCheckItemList() {
      /**
       * default 값을 조회
       * 
       * 초기인 경우 workPermit.checkResults에 값을 그대로 적용
       * 작성중 단계인 경우 default 값에 새로운 항목이 있을시에 workPermit.checkResults에 추가하여 입력을 받을 수 있도록 조치
       * 승인완료, 연장, 취소, 작업완료인 경우 저장된 데이터를 그대로 표시하며 이 경우 default값을 조회하지 않도록 조치
       */
      this.grid.columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '항목',
          align: 'left',
          sortable: true,
        },
        {
          name: 'necessaryFlag',
          field: 'necessaryFlag',
          label: '필요',
          align: 'center',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
        {
          name: 'confirmFlag',
          field: 'confirmFlag',
          label: '확인',
          align: 'center',
          sortable: false,
          type: 'check',
          disableTarget: 'vendorEditable',
          disableCon: 'true',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
      ]
      if (!this.workPermit.plantCd) return;
      
      if (!this.workPermit.checkResults) this.workPermit.checkResults = [];
      if (!this.isOld || this.workPermit.swpStepCd === 'SS00000001') {
        this.$http.url = this.listCheckItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.workPermit.plantCd,
          sopWorkTypeCd: 'SWT0000001',
          useFlag: 'Y',
        }
        this.$http.request((_result) => {
          if (!this.isOld && (!this.workPermit.checkResults || this.workPermit.checkResults.length === 0)) {
            // 최초인 경우 조치항목을 가지고 있지 않음으로 해당 값을 표시
            this.$_.forEach(_result.data, item => {
              this.workPermit.checkResults.push({
                sopWorkPermitId: '',  // 작업허가서 일련번호
                supplementWorkTypeCd: '',  // 보충작업구분
                sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                checkItemName: item.checkItemName, // 점검항목명
                sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                necessaryFlag: 'N',  // 필요
                confirmFlag: 'N',  // 확인
                confirmUserId: '',  // 확인자
                confirmUserName: '',  // 확인자명
                resultContents: '',
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                chgUserId: '',  // 수정자 ID
                editFlag: 'C'
              })
            })
          } else {
            this.$_.forEach(_result.data, item => {
              if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
                this.workPermit.checkResults.push({
                  sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
                  supplementWorkTypeCd: '',  // 보충작업구분
                  sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                  checkItemName: item.checkItemName, // 점검항목명
                  sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                  necessaryFlag: 'N',  // 필요
                  confirmFlag: 'N',  // 확인
                  confirmUserId: '',  // 확인자
                  confirmUserName: '',  // 확인자명
                  resultContents: '',
                  regUserId: this.$store.getters.user.userId,  // 등록자 ID
                  chgUserId: '',  // 수정자 ID
                  editFlag: 'C'
                })
              }
            })
          }
          if (this.$_.findIndex(_result.data, { sopCheckItemKindCd: 'SCIK000005' }) > -1 
            || this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
            this.grid.columns.push({
              name: 'confirmUserName',
              field: 'confirmUserName',
              label: '점검자',
              align: 'center',
              sortable: true,
              type: 'userVendor',
              userId: 'confirmUserId',
              disableTarget: 'vendorEditable',
              disableCon: 'true',
            })
          } else if (this.$_.findIndex(_result.data, { sopCheckItemKindCd: 'SCIK000010' }) > -1 
            || this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000010' }) > -1) {
            this.grid.columns.push({
              name: 'resultContents',
              field: 'resultContents',
              label: '확인(입력)',
              align: 'left',
              sortable: true,
              type: 'text',
              style: 'width:120px',
              disableTarget: 'vendorEditable',
              disableCon: 'true',
            })
          }
        },);
      } else {
        if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
          this.grid.columns.push({
            name: 'confirmUserName',
            field: 'confirmUserName',
            label: '점검자',
            align: 'center',
            sortable: true,
            type: 'userVendor',
            userId: 'confirmUserId',
            disableTarget: 'vendorEditable',
            disableCon: 'true',
          })
        } else if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000010' }) > -1) {
          this.grid.columns.push({
            name: 'resultContents',
            field: 'resultContents',
            label: '확인(입력)',
            align: 'left',
            sortable: true,
            type: 'text',
            style: 'width:120px',
            disableTarget: 'vendorEditable',
            disableCon: 'true',
          })
        } 
      }
    },
    addGas() {
      let pushData = {
        sopGasCheckId: uid(),  // 가스농도측정번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId, // 작업허가서 일련번호
        supplementWorkTypeCd: '',  // 보충작업구분
        checkTime: '',  // 점검시간
        materialName: '',  // 물질명
        gasCheckMachine: '',  // 점검기기명 -> 결과3
        gasCheckUserName: '',
        gasCheckUserId: '',  // 점검자
        gasConfirmUserName: '',
        gasConfirmUserId: '',  // 확인자(입회자)
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C',
      }
      this.$_.forEach(this.useGasItems, gas => {
        this.$set(pushData, gas.sopGasId, '0');
      });
      
      this.workPermit.gases.push(pushData);
    },
    removeGas() {
      let selectData = this.$refs['gasTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000103', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workPermit.deleteGasChecks) this.workPermit.deleteGasChecks = [];
        if (!this.workPermit.deleteGasCheckVals) this.workPermit.deleteGasCheckVals = [];
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.workPermit.deleteGasChecks, { sopGasCheckId: item.sopGasCheckId }) === -1
            && item.editFlag !== 'C') {
            this.workPermit.deleteGasChecks.push(item);
            this.$_.forEach(this.useGasItems, gas => {
              this.workPermit.deleteGasCheckVals.push({
                sopGasCheckId: item.sopGasCheckId,
                sopGasId: gas.sopGasId
              })
            })
          }
          this.workPermit.gases = this.$_.reject(this.workPermit.gases, item);
        });
        this.$refs['gasTable'].$refs['compo-table'].clearSelection();
      }
    },
    vendorChange(vendorCd, beforeVendorCd) {
      if (this.workPermit.workers && this.workPermit.workers.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '해당 작업업체별로 지정된 작업자 정보들이 제외됩니다. 진행하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.workPermit.deleteWorkers) {
              this.workPermit.deleteWorkers = [];
            }
            this.$_.forEach(this.$_.filter(this.workPermit.workers, { vendorCd: beforeVendorCd }), item => {
              if (this.$_.findIndex(this.workPermit.deleteWorkers, { sopWorkerId: item.sopWorkerId }) === -1) {
                this.workPermit.deleteWorkers.push(item)
              }
            })
            this.workPermit.workers = this.$_.reject(this.workPermit.workers, { vendorCd: beforeVendorCd })
          },
          // 취소 callback 함수
          cancelCallback: () => {
            this.$set(this.workPermit, 'vendorCd', beforeVendorCd)
          },
        });
      }
    },
    contentsField(props, col) {
      /**
       * 안전조치사항 항목에서 항목 입력 타입이 '테이블_점검자' 인 경우 테이블에 표시
       * 단, 예전에 '테이블_점검자'였으며 그럼으로 인해 데이터가 저장이 된적이 있는 경우 컴포넌트 표시
       */
      let returnVal = true;
      if (col.name === 'confirmUserName') {
        returnVal = props.row['sopCheckItemKindCd'] === 'SCIK000005' || Boolean(props.row['confirmUserName'])
      } else if (col.name === 'resultContents') {
        returnVal = props.row['sopCheckItemKindCd'] === 'SCIK000010' || Boolean(props.row['resultContents'])
      }
      return returnVal
    },
    tableDataChange(props, col) {
      if (col.name === 'necessaryFlag' && props.row[col.name] !== 'Y') {
        this.$set(props.row, 'confirmFlag', 'N');
      }
    },
  }
};
</script>
<style lang="sass">
.gas-item
  padding-top:18px !important
  .q-field__inner
    padding-bottom: 0px !important
  .customText
    padding-bottom: 0px !important
.gas-card-title
  font-size: 15px
  font-weight: 800
</style>