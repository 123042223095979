var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "일반작업 상세정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "작업개요",
                    name: "workSummary",
                  },
                  model: {
                    value: _vm.workPermit.workSummary,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workSummary", $$v)
                    },
                    expression: "workPermit.workSummary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-process", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "단위공정",
                    name: "processCd",
                  },
                  model: {
                    value: _vm.workPermit.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "processCd", $$v)
                    },
                    expression: "workPermit.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: !_vm.isWriting,
                    label: "작업명",
                    name: "sopName",
                  },
                  model: {
                    value: _vm.workPermit.sopName,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "sopName", $$v)
                    },
                    expression: "workPermit.sopName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-vendor", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "LBL0000224",
                    name: "vendorCd",
                  },
                  on: { dataChange: _vm.vendorChange },
                  model: {
                    value: _vm.workPermit.vendorCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "vendorCd", $$v)
                    },
                    expression: "workPermit.vendorCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "작업장소",
                    name: "workLocation",
                  },
                  model: {
                    value: _vm.workPermit.workLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workLocation", $$v)
                    },
                    expression: "workPermit.workLocation",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "신청업체",
                    name: "request",
                  },
                  model: {
                    value: _vm.request,
                    callback: function ($$v) {
                      _vm.request = $$v
                    },
                    expression: "request",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-4 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    plantCd: _vm.workPermit.plantCd,
                    deptValue: "workManagerDeptCd",
                    type: "dept_user",
                    userType: "vendor",
                    label: "신청인 & 담당자",
                    beforeText: "",
                    name: "workManagerId",
                  },
                  model: {
                    value: _vm.workPermit.workManagerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "workManagerId", $$v)
                    },
                    expression: "workPermit.workManagerId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-checkbox", {
                  attrs: {
                    required: true,
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    codeGroupCd: "SOP_PROTECTIVE_GEAR_CD",
                    isObject: true,
                    valueText: "sopProtectiveGearName",
                    valueKey: "sopProtectiveGearCd",
                    label: "보호구",
                    name: "protectiveGears",
                  },
                  model: {
                    value: _vm.workPermit.protectiveGears,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "protectiveGears", $$v)
                    },
                    expression: "workPermit.protectiveGears",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    rows: 2,
                    label: "특별요구사항",
                    name: "specialRequirements",
                  },
                  model: {
                    value: _vm.workPermit.specialRequirements,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "specialRequirements", $$v)
                    },
                    expression: "workPermit.specialRequirements",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c("c-table", {
              attrs: {
                title: "안전조치 요구사항",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.workPermit.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
              on: { "table-data-change": _vm.tableDataChange },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm no-margin",
                    attrs: { title: "발급 정보" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              plantCd: _vm.workPermit.plantCd,
                              deptValue: "issuedDeptCd",
                              userType: "user",
                              type: "dept_user",
                              label: "발급",
                              beforeText: "",
                              name: "issuedUserId",
                            },
                            model: {
                              value: _vm.workPermit.issuedUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "issuedUserId", $$v)
                              },
                              expression: "workPermit.issuedUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-field", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              deptCd: _vm.workPermit.issuedDeptCd,
                              plantCd: _vm.workPermit.plantCd,
                              deptValue: "examineDeptCd",
                              userType: "user",
                              type: "dept_user",
                              label: "검토 (발급부서)",
                              beforeText: "",
                              name: "examineUserId",
                            },
                            model: {
                              value: _vm.workPermit.examineUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "examineUserId", $$v)
                              },
                              expression: "workPermit.examineUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-field", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              plantCd: "DG",
                              deptCd: "DG200",
                              deptValue: "examineSafehealtyDeptCd",
                              userType: "user",
                              type: "dept_user",
                              label: "검토 (안전보건팀)",
                              beforeText: "",
                              name: "examineSafehealtyUserId",
                            },
                            model: {
                              value: _vm.workPermit.examineSafehealtyUserId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workPermit,
                                  "examineSafehealtyUserId",
                                  $$v
                                )
                              },
                              expression: "workPermit.examineSafehealtyUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-field", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              plantCd: _vm.workPermit.plantCd,
                              userType: "user",
                              deptValue: "approvalDeptCd",
                              type: "dept_user",
                              label: "승인",
                              beforeText: "",
                              name: "approvalUserId",
                            },
                            model: {
                              value: _vm.workPermit.approvalUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "approvalUserId", $$v)
                              },
                              expression: "workPermit.approvalUserId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm no-margin",
                    attrs: { title: "관계 부서 협조자" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-field", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              plantCd: _vm.workPermit.plantCd,
                              deptValue: "relationCooperation1DeptCd",
                              type: "dept_user",
                              label: "협조자-1",
                              beforeText: "",
                              name: "relationCooperation1UserId",
                            },
                            model: {
                              value: _vm.workPermit.relationCooperation1UserId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workPermit,
                                  "relationCooperation1UserId",
                                  $$v
                                )
                              },
                              expression:
                                "workPermit.relationCooperation1UserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-field", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              data: _vm.workPermit,
                              plantCd: _vm.workPermit.plantCd,
                              deptValue: "relationCooperation2DeptCd",
                              type: "dept_user",
                              label: "협조자-2",
                              beforeText: "",
                              name: "relationCooperation2UserId",
                            },
                            model: {
                              value: _vm.workPermit.relationCooperation2UserId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workPermit,
                                  "relationCooperation2UserId",
                                  $$v
                                )
                              },
                              expression:
                                "workPermit.relationCooperation2UserId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }